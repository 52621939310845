<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Dashboard' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Home</span>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Savings' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Savings</span>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Loans' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Loans</span>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Loan Limit' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Loan Limit</span>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Investments' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Invest</span>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Sortika Packages' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <box3d />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Packages</span>
      </li>
      <!-- <li class="nav-item">
        <sidenav-collapse navText="" :to="{ name: 'Billing' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li> -->
      <!-- <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
          PAGES
        </h6>
      </li> -->
      <li class="nav-item">
        <sidenav-collapse
          navText=""
          :to="{ name: 'Profile' }"
          style="margin-bottom: -0.6rem"
        >
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
        <span class="menu-text">Profile</span>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
// import Goal from "../../components/Icon/Goal.vue";
import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// library.add(faHatWizard)

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Sortika UI Dashboard",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    Shop,
    Office,
    CreditCard,
    CustomerSupport,
    Document,
    Box3d,
    // FontAwesomeIcon
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
