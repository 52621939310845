<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <!-- <img
              v-if="profileData.image == null"
              style="border-radius: 9rem !important"
              src="@/assets/img/avatar.png"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            /> -->
            <img
              style="border-radius: 9rem !important"
              :src="
                profileData.image == null
                  ? '@/assets/img/avatar.png'
                  : profileData.image
              "
              alt="profile_image"
              class="shadow-sm w-100 h-100 border-radius-lg"
            />
            <input
              type="file"
              ref="file"
              style="display: none"
              @change="setImage()"
            />
            <button
              href="javascript:;"
              @click="$refs.file.click()"
              class="btn btn-sm btn-icon-only bg-gradient-light position-absolute bottom-0 end-0 mb-n2 me-n2"
            >
              <i
                style="font-size: 0.9rem !important"
                class="fa fa-pen top-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                aria-hidden="true"
                data-bs-original-title="Edit Image"
                aria-label="Edit Image"
              ></i
              ><span class="sr-only">Edit Image</span>
            </button>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ profileData.full_name }}</h5>
            <star-rating
              :inline="true"
              :star-size="14"
              :read-only="true"
              :show-rating="false"
              :rating="4"
            ></star-rating>
            <p
              style="color: black !important"
              class="mb-0 text-sm font-weight-bold"
            >
              Overrall Rating
            </p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active active"
                  data-bs-toggle="tab"
                  id="account"
                  href="#"
                  role="tab"
                  aria-selected="true"
                  aria-controls="Account"
                  v-on:click="makeActive('accountActive')"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 42 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-2319.000000, -291.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            ></path>
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            ></path>
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">My Account</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  id="transactions"
                  href="#"
                  role="tab"
                  aria-selected="false"
                  aria-controls="Transactions"
                  v-on:click="
                    makeActive(
                      'transactionsActive',
                      getTrans(this.currentPage, this.perPage)
                    )
                  "
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>document</title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            ></path>
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">Transactions</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div id="account" v-show="isActiveTab('accountActive')">
      <account-tab :profileData="profileData" />
    </div>
    <div id="transactions" v-show="isActiveTab('transactionsActive')">
      <transactions-tab :transactions="transactions" :analysis="analysis" />
    </div>
  </div>
</template>

<script>
import bruce from "@/assets/img/bruce-mars.jpg";
import team2 from "@/assets/img/team-2.jpg";
// @ts-ignore
import StarRating from "vue-star-rating";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import AccountTab from "./components/AccountTab.vue";
import TransactionsTab from "./components/TransactionsTab.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileOverview",
  components: {
    StarRating,
    TransactionsTab,
    AccountTab,
  },
  data() {
    return {
      showMenu: false,
      choice: "accountActive", // Borrow is chosen by default
      bruce,
      team2,
      faFacebook,
      faTwitter,
      faInstagram,

      profileData: {},
      // investClass: [],
      analysis: [],
      transactions: [],
      currentPage: 1,
      last_page: 0,
      perPage: 10,
      filter: {
        type: "",
        status: "",
      },
    };
  },
  created() {
    this.getProfile();
    // this.getInvestmentsClasses();
    // this.getTrans(this.currentPage, this.perPage);
  },
  methods: {
    // ...mapActions(["getProfile", "getTransactions", "getInvestmentsClasses"]),
    ...mapActions(["getProfile", "getTransactions", "updateUserImage"]),

    getTrans(page, rows) {
      this.getTransactions({ page, rows, query: this.query, ...this.filter });
    },

    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    },

    setImage() {
      const imageFile = this.$refs["file"].files[0];
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const base64Image = reader.result.split(",")[1];

        let imageForm = {
          image: base64Image,
          id: this.profileData.id,
        };

        this.updateUserImage(imageForm);
      });
      reader.readAsDataURL(imageFile);
      // send the base64Image to the server using Axios
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },

  computed: {
    // ...mapGetters(["profile", "TransactionsList", "investmentsClasses"]),
    ...mapGetters(["profile", "TransactionsList"]),
  },
  watch: {
    "TransactionsList.analysis"() {
      this.analysis = this.TransactionsList.analysis;
    },
    "TransactionsList.transList"() {
      this.transactions = this.TransactionsList.transList;
      this.last_page = this.TransactionsList.last_page;
    },
    profile() {
      this.profileData = this.profile;
    },
    // investmentsClasses() {
    //   this.investClass = this.investmentsClasses;
    // },
  },
};
</script>
